import React, { useEffect, useState } from 'react';
import './Products.css';
import Productcard from '../../Components/Productcard/Productcard';
// import { useAuth } from '../../Context/Authcontext';

function Products() {

    // const { splitFunc } = useAuth();
    const [allpro, setAllpro] = useState([]);
    const [currentpage, setCurrentpage] = useState();
    const [filter, setFilter] = useState('');
    const [filtercat, setFiltercat] = useState('');

    useEffect(() => {
        window.scrollTo(0,0);
        if(currentpage){
            sessionStorage.setItem('adcurrentpage', currentpage);
        }
    }, [currentpage]);

    useEffect(() => {
        const foundCurrentpage = sessionStorage.getItem("adcurrentpage");
        if(foundCurrentpage){
            setCurrentpage(parseInt(foundCurrentpage))
        }else{
            setCurrentpage(0)
        }
    }, [])

    useEffect(() => {
        fetch(`https://shahstore.co.in/api/`).then(res => res.json()).then(data => {
            if(data.success){
                setAllpro(data.pro.reverse());
            }else{
                console.log(data);
            }
        }).catch(err => console.log('error occured while getting all the products in products page ', err));
    }, []);

    const mensbrand = ["Audemars Piguet", "Bell & Ross", "Breitling", "Bvlgari", "Cartier", "Franck Muller", "Gucci", "Hublot", "IWC (Schaffhausen)", "Jacob & Co.", "Jaeger Le-coultre (JLC)", "Luminor Panerai", "Omega", "Patek Philippe", "Richard Mille", "Roger Dubuis", "Rolex", "Seiko", "Seven Friday", "Tag Heuer", "Tissot", "Tudor", "U-boat", "Ulysse Nardin", "Armani Exchange (OG)", "Hugo boss(OG)", "Guess(OG)", "Maserati (og)"];

  return (
    <div className='main-products'>
        <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center">
                <h2 className="body-font-bold mb-4 mt-3 fs-3">All Products</h2>
                <div className="d-flex">
                    <select className='form-select w-max-content shadow-none rounded-brand-sm' onChange={(e) => setFiltercat(e.target.value)} value={filtercat}>
                        <option value="category">Category</option>
                        <option value="Men Watch">Men Watch</option>
                        <option value="Women Watch">Women Watch</option>
                        <option value="Swizz Eta Watch">Swizz Eta Watch</option>
                        <option value="Celebrity Watch">Celebrity Watch</option>
                    </select>
                    {
                        filtercat === 'Men Watch' && (
                            <select className='form-select w-max-content shadow-none rounded-brand-sm ms-2' onChange={(e) => setFilter(e.target.value)} value={filter}>
                                <option value="filter">Filter</option>
                                {
                                    mensbrand.map((bran, ind) => (
                                        <option key={ind} value={bran}>{bran}</option>
                                    ))
                                }
                                <option value="Celebrity Watch">Celebrity Watch</option>
                            </select>
                        )
                    }
                </div>
            </div>
            <div className="row p-0 m-0">
                {
                    filtercat === 'Men Watch' ? (
                        allpro?.filter(filpro => ((filter === '' || filter === 'filter') || filpro.brand.toLowerCase() === filter.toLowerCase()) && filpro.category.toLowerCase() === filtercat.toLowerCase()).map((pro, ind) => (
                            <div key={ind} className="col-md-4 col-6 mb-4 p-1">
                                <Productcard coverimg={pro.images[0]} hoverimg={pro.images[1]} tag={pro.status} saletxt={pro.spinningtxt} name={pro.name} price={pro.discountprice} preprice={pro.price} id={pro.proid} />
                            </div>
                        ))
                    ): (
                        allpro?.filter(filpro => (filtercat === '' || filter === 'category') || filpro.category.toLowerCase() === filtercat.toLowerCase()).map((pro, ind) => (
                            <div key={ind} className="col-md-4 col-6 mb-4 p-1">
                                <Productcard coverimg={pro.images[0]} hoverimg={pro.images[1]} tag={pro.status} saletxt={pro.spinningtxt} name={pro.name} price={pro.discountprice} preprice={pro.price} id={pro.proid} />
                            </div>
                        ))
                    )
                }
            </div>
            {/* <div className="d-flex justify-content-center align-items-center mt-4">
                <button className="btn btn-brand-brown pt-2 pb-1 me-3" onClick={() => setCurrentpage(currentpage < 1? 0: currentpage - 1)}><i className="fi fi-rr-arrow-left"></i></button>
                <p className="mb-0 fs-5 body-font-bold"><span className='p-2 px-3 rounded-3 border border-brand-light-brown'>{currentpage + 1}</span> <span className='p-2 rounded-3'>...</span> <span className='p-2 px-3 rounded-3 border'>{Math.floor(allpro?.length / 12) + 1}</span> </p>
                <button className="btn btn-brand-brown pt-2 pb-1 ms-3" onClick={() => setCurrentpage(currentpage === (allpro?.length / 12)? 0: currentpage + 1)}><i className="fi fi-rr-arrow-right"></i></button>
            </div> */}
        </div>
    </div>
  )
}

export default Products